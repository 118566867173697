import { ILink } from "@frontiers-vue3/public-pages";
import { ICategory } from "~/types/page-service";

const mapCategoryToLink = (cms: ICategory): ILink => {
  return {
    text: cms.name,
    url: `/news/category/${cms.slug}`
  };
};

export { mapCategoryToLink };
